const ROLES_FOR_APP = {
    DASHBOARD_VIEW: "view",

    ROLE_LIST: "role_list",
    ROLE_CREATE: "role_create",
    ROLE_UPDATE: "role_update",
    ROLE_DELETE: "role_remove",

    USER_LIST: "user_list",
    USER_CREATE: "user_create",
    USER_UPDATE: "user_update",
    USER_DELETE: "user_remove",

    ADMIN_ACTIVITY_LOGS: 'user_list', // 

    MANAGEMENT_MEDIA: 'media_list',
    MEDIA_CREATE: "media_create",
    MEDIA_UPDATE: "media_update",
    MEDIA_DELETE: "media_remove",
    MEDIA_CONFIG_CPM: "media_update",
    MEDIA_REFRESH_KEY: "media_refersh_key",
    MEDIA_CONFIG_ACCOUNT_LOGIN: "media_create_account_media_console",


    MANAGEMENT_INVENTORY: 'inventory_list',
    INVENTORY_CREATE: "inventory_create",
    INVENTORY_UPDATE: "inventory_update",
    INVENTORY_DELETE: "inventory_remove",

    MANAGEMENT_AGENCY: 'agency_list',
    AGENCY_CREATE: "agency_create",
    AGENCY_UPDATE: "agency_update",
    AGENCY_DELETE: "agency_remove",
    AGENCY_CONFIG_CPM: "agency_update",
    AGENCY_REFRESH_KEY: "agency_refersh_key",
    AGENCY_CONFIG_ACCOUNT_LOGIN: "agency_create_account_agency_console",


    MANAGEMENT_ADVERTISER_AGENCY: 'agency_list', //
    ADVERTISER_AGENCY_CREATE: "agency_create",//
    ADVERTISER_AGENCY_UPDATE: "agency_update",//
    ADVERTISER_AGENCY_DELETE: "agency_remove",//
    ADVERTISER_AGENCY_CONFIG_CPM: "agency_update",//
    ADVERTISER_AGENCY_REFRESH_KEY: "agency_refersh_key",//
    ADVERTISER_AGENCY_CONFIG_ACCOUNT_LOGIN: "agency_create_account_agency_console",//


    MANAGEMENT_ADVERTISER: 'advertiser_list',
    ADVERTISER_CREATE: "advertiser_create",
    ADVERTISER_UPDATE: "advertiser_update",
    ADVERTISER_DELETE: "advertiser_remove",
    ADVERTISER_CONFIG_CPM: "advertiser_update",
    ADVERTISER_REFRESH_KEY: "advertiser_refersh_key",
    ADVERTISER_CONFIG_ACCOUNT_LOGIN: "advertiser_create_account_advertiser_console",

    MANAGEMENT_ADVERTISING: 'advertising_list',
    ADVERTISING_CREATE: "advertising_create",
    ADVERTISING_UPDATE: "advertising_update",
    ADVERTISING_DELETE: "advertising_remove",

    MANAGEMENT_AD_PLATFORM: 'ad_platform_list',
    AD_PLATFORM_CREATE: "ad_platform_create",
    AD_PLATFORM_UPDATE: "ad_platform_update",
    AD_PLATFORM_DELETE: "ad_platform_remove",

    MANAGEMENT_API: 'api_list',
    API_CREATE: "api_create",
    API_UPDATE: "api_update",
    API_DELETE: "api_remove",

    MANAGEMENT_PROXY: 'proxy_list', //
    PROXY_CREATE: "proxy_create",
    PROXY_UPDATE: "proxy_update",
    PROXY_DELETE: "proxy_remove",

    MONITOR_CONFIGURATION_LIST: 'monitor_configuration_list',
    MONITOR_CONFIGURATION_UPDATE: 'monitor_configuration_update',
    MONITOR_SCRIPT: 'monitor_list',
    MONITOR_SCRIPT_ALARM: 'monitor_list',
    MONITOR_SCRIPT_WEBSITE: 'monitor_list',

    MONITOR_EXCEPT_IP_LIST: 'except_ip_list',
    MONITOR_EXCEPT_IP_CREATE: "except_ip_create",
    MONITOR_EXCEPT_IP_UPDATE: "except_ip_update",
    MONITOR_EXCEPT_IP_DELETE: "except_ip_remove",

    MONITOR_BLOCK_IP_LIST: 'block_ip_list',
    MONITOR_BLOCK_IP_CREATE: "block_ip_create",
    MONITOR_BLOCK_IP_UPDATE: "block_ip_update",
    MONITOR_BLOCK_IP_DELETE: "block_ip_remove",


    MONITOR_EXCEPT_UUID_LIST: 'except_uuid_list',
    MONITOR_EXCEPT_UUID_CREATE: "except_uuid_create",
    MONITOR_EXCEPT_UUID_UPDATE: "except_uuid_update",
    MONITOR_EXCEPT_UUID_DELETE: "except_uuid_remove",

    MONITOR_BLOCK_UUID_LIST: 'block_uuid_list',
    MONITOR_BLOCK_UUID_CREATE: "block_uuid_create",
    MONITOR_BLOCK_UUID_UPDATE: "block_uuid_update",
    MONITOR_BLOCK_UUID_DELETE: "block_uuid_remove",

    MONITOR_USER_LIST: "user_monitor_list",
    MONITOR_USER_CREATE: "user_monitor_create",
    MONITOR_USER_UPDATE: "user_monitor_update",
    MONITOR_USER_DELETE: "user_monitor_remove",

    REPORT_MEDIA: 'media_report',
    REPORT_MEDIA_V2: 'media_report_v2',
    REPORT_MEDIA_SALES: 'media_report_sales',
    REPORT_INVENTORY: 'inventory_report',
    REPORT_AD_PLATFORM: 'ad_platform_report',
    REPORT_AD_TYPE: 'ad_type_report',
    REPORT_AGENCY: 'agency_report',
    REPORT_REVENUE: 'revenue_report',
    REPORT_COMMISSION: 'comission_report',
    REPORT_SETTLEMENT: 'settlement_report',
    REPORT_COUPANG: 'coupang_report',
    REPORT_ADVERTISER: 'advertiser_report',
    REPORT_ADVERTISER_AGENCY: 'advertiser_report', //'advertiser_agency_report',
    REPORT_ADVERTISING: 'advertising_report',
    REPORT_COUPANG_NASMEDIA: 'coupang_report', //coupang_nasmedia_report
    REPORT_AD_PLATFORM_SALES: 'ad_platform_report_sales',
    REPORT_AD_PLATFORM_MEDIA: 'ad_platform_report_sales',
    REPORT_CLICK: 'media_report', //
    REPORT_INTERWORDS: 'interworks_report',

    ADS_LOG: 'ads_log',

    IP_STATISTICS: 'ip_statistics',
    MEDIA_IP_STATISTICS: 'media_ip_statistics',
    UUID_STATISTICS: 'uuid_statistics',
    UUID_BLOCKS: 'uuid_block',
    UUID_EXCEPT: 'uuid_except',
    IP_BLOCKS: 'ip_block',
    IP_EXCEPT: 'ip_except',
    DAILY_STATISTICS: 'daily_statistics',
    HOURLY_STATISTICS: 'hourly_statistics',
    MEDIA_HOURLY_STATISTICS: 'media_hourly_statistics',
    REFERRAL_STATISTICS: 'referral_statistics',
    MEDIA_STATISTICS: 'media_statistics',
    PATH_STATISTICS: 'path_statistics',

};

const isHavePermissionRole = (keyRoles: string | string[], permissions: { id: string | number, name: string }[] = []): boolean => {
    const roles = typeof keyRoles === 'string' ? [keyRoles] : keyRoles;
    return permissions?.some(item => roles?.includes(item.name));
};

export { ROLES_FOR_APP, isHavePermissionRole };