import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { IClickReport, IDailyReport, IHourlyReport, IMedia, IMediaReport } from "./types/_media";
const api = new APIClient();

const path = '/medias';
const mediaApi = {
  medias(params: any): Promise<AxiosResponse<ResponseData<IMedia[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  getMedia(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  uploadLogoMedia(dataForm: any): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const formData = new FormData();
    if (dataForm) {
      formData.append('files', dataForm);
    }
    const url = `${path}/upload`;
    return api.post(url, formData, {
      timeout: 1000 * 60 * 10,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  postMedia(dataForm: any): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const url = `${path}`;
    return api.post(url, dataForm);
  },
  putMedia(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const url = `${path}/${id}`;
    return api.put(url, dataForm);
  },
  deleteMedia(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
  putAccountMedia(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const url = `${path}/${id}/account`;
    return api.put(url, dataForm);
  },
  allMedias(params: any = {}): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const url = `/masters/medias`;
    return api.get(url, params)
  },
  mediaReports(params: any = {}): Promise<AxiosResponse<ResponseData<IMediaReport>, any>> {
    const url = `/reports/media`;
    return api.get(url, params)
  },
  clickReports(params: any = {}): Promise<AxiosResponse<ResponseData<IClickReport>, any>> {
    const url = `/reports/media`;
    return api.get(url, params)
  },
  interworkReports(params: any = {}): Promise<AxiosResponse<ResponseData<IClickReport>, any>> {
    const url = `/reports/interworks`;
    return api.get(url, params)
  },
  mediaReportsV2(params: any = {}): Promise<AxiosResponse<ResponseData<IMediaReport>, any>> {
    const url = `/reports/media-v2`;
    return api.get(url, params)
  },
  mediaReportsSales(params: any = {}): Promise<AxiosResponse<ResponseData<IMediaReport>, any>> {
    const url = `/reports/media-sales`;
    return api.get(url, params)
  },
  dailyReports(params: any = {}): Promise<AxiosResponse<ResponseData<IDailyReport>, any>> {
    const url = `/reports/media-daily`;
    return api.get(url, params)
  },
  hourlyReports(params: any = {}): Promise<AxiosResponse<ResponseData<IHourlyReport>, any>> {
    const url = `/reports/media-hourly`;
    return api.get(url, params)
  },
  mediaHourlyReports(params: any = {}): Promise<AxiosResponse<ResponseData<IHourlyReport>, any>> {
    const url = `/reports/media-hourly-report`;
    return api.get(url, params)
  },
  getGenerateApiKeyMedia(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const url = `${path}/${id}/refresh`;
    return api.get(url, params)
  },

}
export default mediaApi
