import { Option } from "api/types/_public";

export const SCHEDULE_OPTIONS: Option[] = [
  {
    label: "Now",
    value: "now",
  },
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
];

export const SCHEDULE_MONTHLY_OPTIONS: Option[] = Array.from(
  { length: 31 },
  (_, index) => {
    const day = (index + 1).toString().padStart(2, "0");
    return { label: `${day}th`, value: day };
  }
);

export const ARR_INDEX_TEXT_DAY_OF_WEEK: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const SCHEDULE_WEEKLY_OPTIONS: Option[] = [
  {
    label: "Monday",
    value: "1",
  },
  {
    label: "Tuesday",
    value: "2",
  },
  {
    label: "Wednesday",
    value: "3",
  },
  {
    label: "Thursday",
    value: "4",
  },
  {
    label: "Friday",
    value: "5",
  },
  {
    label: "Saturday",
    value: "6",
  },
  {
    label: "Sunday",
    value: "7",
  },
];

export const TYPE_SHOW_MORE_HASHTAG = {
  SCROLL: 'scroll',
  MODAL: 'modal'
}

export const STATUS_ADVERTISER_OPTIONS: Option[] = [
  {
    label: "Status_Advertiser_0", // Inactive
    value: "0",
  },
  {
    label: "Status_Advertiser_1", // Active
    value: "1",
  }
];

export const STATUS_ADVERTISING_OPTIONS: Option[] = [
  {
    label: "Status_Advertising_0", // Inactive
    value: "0",
  },
  {
    label: "Status_Advertising_1", // Active
    value: "1",
  }
];

export enum TYPE_BANNER_ADVERTISING {
  SINGLE_BANNER = 1,
  SLIDE_BANNER = 2,
};

export const TYPE_BANNER_ADVERTISING_OPTIONS: Option[] = [
  {
    label: `Type_Banner_Advertising_${TYPE_BANNER_ADVERTISING.SINGLE_BANNER}`,
    value: String(TYPE_BANNER_ADVERTISING.SINGLE_BANNER),
  },
  {
    label: `Type_Banner_Advertising_${TYPE_BANNER_ADVERTISING.SLIDE_BANNER}`,
    value: String(TYPE_BANNER_ADVERTISING.SLIDE_BANNER),
  },
];


export const STATUS_MEDIA_OPTIONS: Option[] = [
  {
    label: "Status_Media_0", // Inactive
    value: "0",
  },
  {
    label: "Status_Media_1", // Active
    value: "1",
  }
];

export const STATUS_AGENCY_OPTIONS: Option[] = [
  {
    label: "Status_Agency_0", // Inactive
    value: "0",
  },
  {
    label: "Status_Agency_1", // Active
    value: "1",
  }
];

export const STATUS_ADVERTISER_AGENCY_OPTIONS: Option[] = [
  {
    label: "Status_Agency_0", // Inactive
    value: "0",
  },
  {
    label: "Status_Agency_1", // Active
    value: "1",
  }
];

export const STATUS_PROXY_OPTIONS: Option[] = [
  {
    label: "Status_Proxy_1", // AVAILABLE
    value: "1",
  },
  {
    label: "Status_Proxy_2", // UNAVAILABLE
    value: "2",
  }
];


export const STATUS_MONITOR_SCRIPT_URL: Option[] = [
  {
    label: "Status_Url_Script_1", // UNKNOWN
    value: "1",
  },
  {
    label: "Status_Url_Script_2", // AVAILABLE
    value: "2",
  },
  {
    label: "Status_Url_Script_3", // NOT_FOUND
    value: "3",
  },
];

export const STATUS_MONITOR_INSTALLATION_OPTIONS: Option[] = [
  {
    label: "Status_Installation_3", // Uninstalled
    value: "3",
  },
  {
    label: "Status_Installation_2", // Installed
    value: "2",
  },
];

export const STATUS_INVENTORY_OPTIONS: Option[] = [
  {
    label: "Status_Inventory_0", // Inactive
    value: "0",
  },
  {
    label: "Status_Inventory_1", // Active
    value: "1",
  }
];

export const TYPE_ADVERTISER_OPTIONS: Option[] = [
  {
    label: "Type_Advertiser_1", // Personal
    value: "1",
  },
  {
    label: "Type_Advertiser_2", // Business
    value: "2",
  },
];

export const AD_PLATFORM_INVENTORY_TYPE_COUPANG = '1'; // Coupang

export const AD_PLATFORM_INVENTORY_TYPE_DIGITAL_CAMP = '2'; // Digital Camp

export const AD_PLATFORM_INVENTORY_TYPE_DIGITAL_CAMP_SCRIPT = '3'; // Digital Camp (Script)

export const AD_PLATFORM_INVENTORY_TYPE_SELF_ADVERTISING = '4'; // Self-Advertising

export const AD_PLATFORM_INVENTORY_TYPE_COUPANG_NASMEDIA = '5'; // Coupang (nasmedia)

export const AD_PLATFORM_INVENTORY_TYPE_GMARKET_NASMEDIA = '6'; // Gmarket (nasmedia)

export const AD_PLATFORM_INVENTORY_TYPE_ADWISER = '7'; // Adwiser

export const AD_PLATFORM_INVENTORY_TYPE_TPMN = '8'; // TPMN

export const AD_PLATFORM_INVENTORY_TYPE_COUPANG_NASMEDIA_KEYWORD = '9'; // Coupang (nasmedia:keyword)




export const AD_PLATFORM_INVENTORY_OPTIONS: Option[] = [
  {
    label: "Ad_Platform_Inventory_1", // Coupang
    value: AD_PLATFORM_INVENTORY_TYPE_COUPANG,
  },
  {
    label: "Ad_Platform_Inventory_2", // Digital Camp
    value: AD_PLATFORM_INVENTORY_TYPE_DIGITAL_CAMP,
  },
  {
    label: "Ad_Platform_Inventory_3", // Digital Camp (Script)
    value: AD_PLATFORM_INVENTORY_TYPE_DIGITAL_CAMP_SCRIPT,
  },
  {
    label: "Ad_Platform_Inventory_4", // Self-Advertising
    value: AD_PLATFORM_INVENTORY_TYPE_SELF_ADVERTISING,
  },
  {
    label: "Ad_Platform_Inventory_5", // Coupang (nasmedia)
    value: AD_PLATFORM_INVENTORY_TYPE_COUPANG_NASMEDIA,
  },
  {
    label: "Ad_Platform_Inventory_9", // Coupang (nasmedia:keyword) 
    value: AD_PLATFORM_INVENTORY_TYPE_COUPANG_NASMEDIA_KEYWORD,
  },
  {
    label: "Ad_Platform_Inventory_6", // Gmarket (nasmedia)
    value: AD_PLATFORM_INVENTORY_TYPE_GMARKET_NASMEDIA,
  },
  {
    label: "Ad_Platform_Inventory_7", // Adwiser
    value: AD_PLATFORM_INVENTORY_TYPE_ADWISER,
  },
  {
    label: "Ad_Platform_Inventory_8", // TPMN
    value: AD_PLATFORM_INVENTORY_TYPE_TPMN,
  },
];

export const AD_TYPE_INVENTORY_IS_FIXED_BANNER = '2'; // Fixed Banner

export const AD_TYPE_INVENTORY_OPTIONS: Option[] = [
  {
    label: "Ad_Type_Inventory_1", // Floating Banner 
    value: "1",
  },
  {
    label: "Ad_Type_Inventory_2", // Fixed Banner 
    value: AD_TYPE_INVENTORY_IS_FIXED_BANNER,
  },
];

export const BANNER_TYPE_INVENTORY_SLIDER_BANNER_V2 = '3'; // SLIDER_BANNER V2

export const BANNER_TYPE_INVENTORY_OPTIONS: Option[] = [
  {
    label: "Banner_Type_Inventory_1", // INDEPENDENT_BANNER
    value: "1",
  },
  {
    label: "Banner_Type_Inventory_2", // SLIDER_BANNER
    value: "2",
  },
  {
    label: "Banner_Type_Inventory_3", // SLIDER_BANNER V2
    value: BANNER_TYPE_INVENTORY_SLIDER_BANNER_V2,
  },
];


export const SLIDE_BANNER_RATIO_TYPE_INVENTORY_OPTIONS: Option[] = [
  {
    label: "0%",
    value: "0",
  },
  {
    label: "50%",
    value: "50",
  },
  {
    label: "100%",
    value: "100",
  },
];




export const AD_TYPE_INVENTORY_LOG_OPTIONS: Option[] = [
  {
    label: "Ad_Type_Inventory_1", // Floating Banner 
    value: "FLOATING_BANNER",
  },
];


export const STATUS_BLOCK_UUID_OPTIONS: Option[] = [
  {
    label: "Status_Block_0", // Normal
    value: "0",
  },
  {
    label: "Status_Block_1", // Block
    value: "1",
  }
];

export const STATUS_EXCEPT_UUID_OPTIONS: Option[] = [
  {
    label: "Status_Except_0", // Normal
    value: "0",
  },
  {
    label: "Status_Except_1", // Block
    value: "1",
  }
];


export const TYPE_BLOCK_UUID_OPTIONS: Option[] = [
  {
    label: "uuid_list", // uuid form list
    value: "uuid_list",
  },
  {
    label: "manual", // Manual
    value: "manual",
  },
  {
    label: "auto", // Auto
    value: "auto",
  }
];

export const TYPE_BLOCK_IP_OPTIONS: Option[] = [
  {
    label: "ip_list", // ip form list
    value: "list",
  },
  {
    label: "manual", // Manual
    value: "manual",
  },
  {
    label: "auto", // Auto
    value: "auto",
  }
];

export const POSITION_INVENTORY_OPTIONS: Option[] = [
  {
    label: "Position_Inventory_left", // Left
    value: "left",
  },
  {
    label: "Position_Inventory_right", // Right
    value: "right",
  }
];

export const IMPRESSION_INVENTORY_OPTIONS: Option[] = [
  {
    label: "Impression_Inventory_directly", // Directly
    value: "directly",
  },
  {
    label: "Impression_Inventory_scrolling_down", // Scrolling
    value: "scrolling_down",
  },
  {
    label: "Impression_Inventory_scrolling_up", // Scrolling
    value: "scrolling_up",
  }
];

export const BUTTON_POSITION_INVENTORY_OPTIONS: Option[] = [
  {
    label: "Top-Left", // Top-Left
    value: "top-left",
  },
  {
    label: "Top-Right", // Top-Right
    value: "top-right",
  },
  {
    label: "Bottom-Left", // Bottom-Left
    value: "bottom-left",
  },
  {
    label: "Bottom-Right", // Bottom-Right
    value: "bottom-right",
  }
];

export const INTEGRATION_METHOD_OPTIONS: Option[] = [
  {
    label: "non-iframe", // non-iframe
    value: "non-iframe",
  },
  {
    label: "iframe", // iframe
    value: "iframe",
  }
];


export const FILL_RATE_TIME_OPTIONS: Option[] = [
  {
    label: "01",
    value: "1",
  },
  {
    label: "02",
    value: "2",
  },
  {
    label: "03",
    value: "3",
  },
  {
    label: "04",
    value: "4",
  },
  {
    label: "05",
    value: "5",
  },
  {
    label: "06",
    value: "6",
  },
  {
    label: "07",
    value: "7",
  },
  {
    label: "08",
    value: "8",
  },
  {
    label: "09",
    value: "9",
  },
  {
    label: "10",
    value: "10",
  },
  {
    label: "11",
    value: "11",
  },
  {
    label: "12",
    value: "12",
  },
  {
    label: "13",
    value: "13",
  },
  {
    label: "14",
    value: "14",
  },
  {
    label: "15",
    value: "15",
  },
  {
    label: "16",
    value: "16",
  },
  {
    label: "17",
    value: "17",
  },
  {
    label: "18",
    value: "18",
  },
  {
    label: "19",
    value: "19",
  },
  {
    label: "20",
    value: "20",
  },
  {
    label: "21",
    value: "21",
  },
  {
    label: "22",
    value: "22",
  },
  {
    label: "23",
    value: "23",
  },
  {
    label: "24",
    value: "24",
  },
];


export const OPERATING_OPTIONS: Option[] = [
  {
    label: "00:00",
    value: "0",
  },
  {
    label: "01:00",
    value: "1",
  },
  {
    label: "02:00",
    value: "2",
  },
  {
    label: "03:00",
    value: "3",
  },
  {
    label: "04:00",
    value: "4",
  },
  {
    label: "05:00",
    value: "5",
  },
  {
    label: "06:00",
    value: "6",
  },
  {
    label: "07:00",
    value: "7",
  },
  {
    label: "08:00",
    value: "8",
  },
  {
    label: "09:00",
    value: "9",
  },
  {
    label: "10:00",
    value: "10",
  },
  {
    label: "11:00",
    value: "11",
  },
  {
    label: "12:00",
    value: "12",
  },
  {
    label: "13:00",
    value: "13",
  },
  {
    label: "14:00",
    value: "14",
  },
  {
    label: "15:00",
    value: "15",
  },
  {
    label: "16:00",
    value: "16",
  },
  {
    label: "17:00",
    value: "17",
  },
  {
    label: "18:00",
    value: "18",
  },
  {
    label: "19:00",
    value: "19",
  },
  {
    label: "20:00",
    value: "20",
  },
  {
    label: "21:00",
    value: "21",
  },
  {
    label: "22:00",
    value: "22",
  },
  {
    label: "23:00",
    value: "23",
  },
  {
    label: "24:00",
    value: "24",
  },
];



export const STATUS_ALERT_MODE_OPTIONS: Option[] = [
  {
    label: "Status_Alert_Mode_0", // SILENT
    value: "0",
  },
  {
    label: "Status_Alert_Mode_1", // NORMAL
    value: "1",
  }
];

export const COLORS_CONFIGURATION_STATUS = ["danger", "success"];

export const COLORS_STATUS_CAMPAIGN: any = {
  pending: "secondary",
  approved: "primary",
  completed: "success",
  rejected: "danger"
};


export enum DEVICE_DISPLAY_ADS_TYPE {
  MOBILE = 'mobile',
  PC = 'pc',
}

export const DEVICE_DISPLAY_ADS_OPTIONS: Option[] = [
  {
    label: "Mobile", // Mobile
    value: `${DEVICE_DISPLAY_ADS_TYPE.MOBILE}`,
  },
  {
    label: "PC", // PC
    value: `${DEVICE_DISPLAY_ADS_TYPE.PC}`,
  },
];

export const OS_DISPLAY_ADS_OPTIONS: Option[] = [
  {
    label: "All", // All
    value: 'all',
  },
  {
    label: "AOS", // AOS
    value: 'aos',
  },
  {
    label: "iOS", // iOS
    value: 'ios',
  },
];

export const PLATFORM_LOG_OPTIONS: Option[] = [
  {
    label: "Platform_naver",
    value: "naver",
  },
  {
    label: "Platform_google",
    value: "google",
  },
  {
    label: "Platform_daum",
    value: "daum",
  },
  {
    label: "Platform_etc",
    value: "etc",
  },
];

export const AD_PLATFORM_LOG_OPTIONS: Option[] = [
  // {
  //   label: "Platform_ad_system",
  //   value: "ad_system",
  // },
  {
    label: "Platform_coupang",
    value: "coupang",
  },
  {
    label: "Platform_dc",
    value: "dc",
  },
];

export const AD_TYPE_OPTIONS: Option[] = [
  {
    label: "Type_close",
    value: "close",
  },
  {
    label: "Type_click",
    value: "click",
  },
  {
    label: "Type_impression",
    value: "impression",
  },
  {
    label: "Type_request",
    value: "request",
  },
  {
    label: "Type_block",
    value: "block",
  },
];

export const CLICK_TYPE_NORMAL = 'normal';
export const CLICK_TYPE_FORCE = 'force_click_layer';
export const CLICK_TYPE_AUTO_POPUP = 'auto_click_popup';
export const CLICK_TYPE_AUTO_REDIRECT = 'auto_redirect';


export const CLICK_TYPE_OPTIONS: Option[] = [
  {
    label: "Normal",
    value: CLICK_TYPE_NORMAL,
  },
  {
    label: "Force Click (Layer)",
    value: CLICK_TYPE_FORCE,
  },
  {
    label: "Automatic Click (Popup)",
    value: CLICK_TYPE_AUTO_POPUP,
  },
  {
    label: "Automatic Redirect",
    value: CLICK_TYPE_AUTO_REDIRECT,
  },
];

export const REFERRAL_TYPE_OPTIONS: Option[] = [
  {
    label: "Referral_type_external",
    value: "external",
  },
  {
    label: "Referral_type_internal",
    value: "internal",
  },
  {
    label: "Referral_type_main",
    value: "main",
  },
  {
    label: "Referral_type_none",
    value: "none",
  },
];

export const VERSION_OPTIONS: Option[] = [
  {
    label: "0.0.1",
    value: "0.0.1",
  },
  {
    label: "0.0.2",
    value: "0.0.2",
  },
  {
    label: "0.0.3",
    value: "0.0.3",
  },
  {
    label: "0.0.4",
    value: "0.0.4",
  },
  {
    label: "0.0.5",
    value: "0.0.5",
  },
  {
    label: "0.0.6",
    value: "0.0.6",
  },
  {
    label: "0.0.7",
    value: "0.0.7",
  },
  {
    label: "0.0.8",
    value: "0.0.8",
  },
  {
    label: "0.0.9",
    value: "0.0.9",
  },
  {
    label: "0.1.0",
    value: "0.1.0",
  },
  {
    label: "0.2.0",
    value: "0.2.0",
  },
  {
    label: "0.2.1",
    value: "0.2.1",
  },
  {
    label: "0.2.2",
    value: "0.2.2",
  },
  {
    label: "0.2.3",
    value: "0.2.3",
  },
  {
    label: "0.2.4",
    value: "0.2.4",
  },
  {
    label: "0.2.5",
    value: "0.2.5",
  },
  {
    label: "0.2.6",
    value: "0.2.6",
  },
  {
    label: "0.2.7",
    value: "0.2.7",
  },
  {
    label: "0.2.8",
    value: "0.2.8",
  },
  {
    label: "0.2.9",
    value: "0.2.9",
  }
].reverse();


export const DEVICE_OPTIONS: Option[] = [
  {
    label: "Device_pc",
    value: "pc",
  },
  {
    label: "Device_mobile",
    value: "mobile",
  },
];

export const TYPE_ADS_DEVICE_OPTIONS: Option[] = [
  {
    label: "Mobile",
    value: "Mobile",
  },
  {
    label: "PC",
    value: "PC",
  },
];

export const BOT_OPTIONS: Option[] = [
  {
    label: "Bot_0",
    value: '0',
  },
  {
    label: "Bot_1",
    value: '1',
  },
];

export const BROWSER_OPTIONS: Option[] = [
  {
    label: "Browser_Samsung",
    value: 'Samsung Browser',
  },
  {
    label: "Browser_Chrome",
    value: 'Chrome',
  },
  {
    label: "Browser_Safari",
    value: 'Safari',
  },
  {
    label: "Browser_Unknown_Browser",
    value: 'Unknown Browser',
  },
];


export const IS_OPTIONS: Option[] = [
  {
    label: "YES",
    value: "1",
  },
  {
    label: "NO",
    value: "0",
  },
];




export const OS_OPTIONS: Option[] = [
  {
    label: "OS_iOS",
    value: 'iOS',
  },
  {
    label: "OS_Mac",
    value: 'Mac',
  },
  {
    label: "OS_Windows",
    value: 'Windows',
  },
  {
    label: "OS_Android",
    value: 'Android',
  },
  {
    label: "OS_Etc",
    value: 'Etc',
  },
];


export const TYPE_NEWS_OPTIONS: Option[] = [
  {
    label: "Type_News_1", // Trend News
    value: "1",
  },
  {
    label: "Type_News_0", // Media News
    value: "0",
  },
  {
    label: "Type_News_2", // Not News
    value: "2",
  },
];

export const METHOD_OPTIONS: Option[] = [
  {
    label: "GET",
    value: "GET",
  },
  {
    label: "POST",
    value: "POST",
  },
  {
    label: "PUT",
    value: "PUT",
  },
  {
    label: "DELETE",
    value: "DELETE",
  },
];

export enum CPM_TYPE {
  MEDIA = 1,
  AGENCY = 2,
  INVENTORY = 3,
}

export enum CPC_TYPE {
  MEDIA = 1,
  AGENCY = 2,
}

export enum ADVERTISER_CPC_TYPE {
  ADVERTISER = 1,
  ADVERTISER_AGENCY = 2,
}

export const TYPE_CAUSE: any = {
  FIRST_TIME_VISITORS_MOBILE: '[Media Name] first-time visitors accessing via Mobile',
  FIRST_TIME_VISITORS_PC: '[Media Name] first-time visitors accessing via PC',
  EXCEPT_UUID_WITHOUT_REFERRAL: '[Media Name] visitors who accessed without a referrer in the last [Day] days and had [Impression] or more impressions',
  EXCEPT_HOST_VISOTOR_MOBILE: '[Media Name] [Host] [Keyword] is the host in the referrer URL via Mobile',
  EXCEPT_HOST_VISOTOR_PC: '[Media Name] [Host] [Keyword] is the host in the referrer URL via PC',
  EXCEPT_REFERRAL_PATH_VISOTOR_MOBILE: '[Media Name] [URL] [Keyword] is the path in the referrer URL via Mobile',
  EXCEPT_REFERRAL_PATH_VISOTOR_PC: '[Media Name] [URL] [Keyword] is the path in the referrer URL via PC',
};

export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];